import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API.js';
import { htmlIf } from '../../Utils/HTML';
import { DismissibleModal } from '../../Components/Modal';

type InviteToConsultModalProps =
  { customerId: number
  , customerName: string
  , isVisible: boolean
  , onClose: () => void;
  }

export const InviteToConsultModal = (props: InviteToConsultModalProps) => {
  const [message, setMessage] = useState('');

  const [showErrors, setShowErrors] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  function sendConsultInvite() {
    setIsSaving(true);

    const body =
      { customerId: props.customerId
      , message: message
      }

    API.post("advisor_meetings_send_consult_invite_path", body).then(function (result) {
      if (result['error']) {
      } else {
        props.onClose();
        setMessage('');
      }
      setIsSaving(false);
    })
  }

  function handleSendClicked() {
    if (message.length > 0) {
      sendConsultInvite();
    } else {
      setShowErrors(true);
    }
  }

  function handleCancelClicked() {
    props.onClose();
    setMessage('');
  }

  return (
    <div>
      {htmlIf(props.isVisible,
        <DismissibleModal
          dialogClass='modal-lg'
          title={<h4>Consult Invitation</h4>}
          body={
            <div>
              <div className="mb-3">
                Would you like to invite {props.customerName} to schedule a free consultation with you?
                They will be emailed a one-time link that will allow them to book a meeting on your calendar
                with no payment.
              </div>
              <div className="fs-md text-gray-900 mb-1">Invitation Message</div>
              <textarea className="form-control"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                rows={5}
                placeholder='Enter a message to go along with your invitation link…'
              />
              {htmlIf(showErrors && !(message.length > 0),
                <div className="fs-sm mt-1 text-danger fw-bold">Please enter a message.</div>
              )}
              <div className="mt-3 text-end">
                <button className="btn btn-outline-danger me-3" onClick={handleCancelClicked} disabled={isSaving}>Cancel</button>
                <button className="btn btn-primary" onClick={handleSendClicked} disabled={isSaving}>
                  <i className="ai-calendar-plus me-1"/>
                  Send Invitation
                </button>
              </div>
            </div>
          }
          onDismiss={props.onClose}
      />
      )}
    </div>
  )
}
