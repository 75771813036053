import * as React from 'react';
import Select from 'react-select';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../../Utils/API';
import { Advisor, ProfessionalOrganization } from '../../../Types/Advisor';
import { College, collegeToReactSelectItem, reactSelectItemToCollege } from '../../../Types/College';
import { htmlIf, formatMultiParagraphString } from '../../../Utils/HTML';
import * as Language from '../../../Utils/Language';

type Props =
  { advisor: Advisor
  , allProfessionalOrganizations: ProfessionalOrganization[]
  , allColleges: College[]
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

const AboutMe = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const [advisingProcess, setAdvisingProcess] = useState(props.advisor.advisingProcess);
  const [bio, setBio] = useState(props.advisor.bio);
  const [selectedLanguages, setSelectedLanguages] = useState(props.advisor.languageCodes.map((code) => Language.asSelectOption(code)));
  const [location, setLocation] = useState(props.advisor.location);
  const [preferredName, setPreferredName] = useState(props.advisor.preferredName);
  const [pronouns, setPronouns] = useState(props.advisor.pronouns);
  const [shortEducation, setShortEducation] = useState(props.advisor.shortEducation);
  const [yearsOfExperience, setYearsOfExperience] = useState(props.advisor.yearsOfExperience?.toString());
  const [isOpenToWork, setIsOpenToWork] = useState(props.advisor.isOpenToWork);

  const [selectedProfessionalOrgs, setSelectedProfessionalOrgs] = useState(
    props.advisor.professionalOrganizations.map((org) => ({value: org.id, label: org.name}))
  );
  const [selectedColleges, setSelectedColleges] = useState(
    props.advisor.colleges.map((college) => (collegeToReactSelectItem(college)))
  );

  function saveAboutMe() {
    const profOrgs: ProfessionalOrganization[] = selectedProfessionalOrgs.map((org1) => (
      props.allProfessionalOrganizations.find((org2) => org1.value === org2.id))
    );
    const colleges: College[] = selectedColleges.map((item) => (reactSelectItemToCollege(item, props.allColleges)));

    const postBody = {
      advisingProcess: advisingProcess,
      bio: bio,
      languageCodes: selectedLanguages.map((languageItem: ReactSelectOption) => languageItem.value),
      location: location,
      preferredName: preferredName,
      pronouns: pronouns,
      shortEducation: shortEducation,
      yearsOfExperience: yearsOfExperience,
      isOpenToWork: isOpenToWork,
      professionalOrganizations: profOrgs,
      colleges: colleges
    }

    API.post("advisor_account_update_profile_info_path", postBody).then(function (result) {
      setIsEditing(false)
    })
  }

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between py-1">
        <h4 className="mb-0">About Me</h4>
        {htmlIf(!isEditing,
          <button onClick={() => setIsEditing(true)} className="btn btn-link d-flex">
            <i className="ai-edit-alt me-1"></i>
            Edit
          </button>
        )}
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-6">
            <h5 className="mb-1">Preferred Name</h5>
            { isEditing
            ? <input
                onChange={(e) => setPreferredName(e.target.value)}
                className="form-control"
                placeholder='Enter your name as it should be displayed to other users…'
                value={preferredName}
              />
            : <div>{preferredName}</div>
            }
          </div>
          <div className="col-lg-6 mt-2 mt-lg-0">
            <h5 className="mb-1">Pronouns</h5>
            { isEditing
            ? <input
                onChange={(e) => setPronouns(e.target.value)}
                className="form-control"
                value={pronouns}
              />
            : <div>{pronouns}</div>
            }
          </div>
          <div className="col-lg-6 mt-2">
            <h5 className="mb-1">Years of Experience</h5>
            { isEditing
            ? <input
                onChange={(e) => setYearsOfExperience(e.target.value)}
                className="form-control"
                value={yearsOfExperience}
              />
            : <div>{yearsOfExperience} years</div>
            }
          </div>
          <div className="col-lg-6 mt-2">
            <h5 className="mb-1">Short Education</h5>
            { isEditing
            ? <input
                onChange={(e) => setShortEducation(e.target.value)}
                className="form-control"
                placeholder='This is the education field displayed on your profile card…'
                value={shortEducation}
              />
            : <div>{shortEducation}</div>
            }
          </div>
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Bio</h5>
          { isEditing
          ? <textarea
              onChange={(e) => setBio(e.target.value)}
              className="form-control"
              rows={5}
              placeholder='Enter your bio…'
              maxLength={5000}
              value={bio}
            />
          : <div>{formatMultiParagraphString(bio)}</div>
          }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Advising Process</h5>
          { isEditing
          ? <textarea
              onChange={(e) => setAdvisingProcess(e.target.value)}
              className="form-control"
              rows={5}
              placeholder='How do you work with clients? Share more on your advising process here…'
              maxLength={5000}
              value={advisingProcess}
            />
          : <div>{formatMultiParagraphString(advisingProcess)}</div>
          }
        </div>
        <div className="mt-2">
            <h5 className="mb-1">Location</h5>
            { isEditing
            ? <input
                onChange={(e) => setLocation(e.target.value)}
                className="form-control"
                value={location}
                placeholder="Where are you based out of?"
              />
            : <div>{location}</div>
            }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Professional Organizations</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allProfessionalOrganizations.map((org) => ({value: org.id, label: org.name}))}
              onChange={(newOrgs: ReactSelectOption[]) => setSelectedProfessionalOrgs(newOrgs)}
              value={selectedProfessionalOrgs}
              placeholder='Enter any organizations that you’re a member of…'
            />
          : <div>
              {selectedProfessionalOrgs.map((org) => (
                <div key={org.label} className="badge bg-accent text-dark me-1 d-inline-block">{org.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">I’ve helped students get accepted to…</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allColleges.map((college) => (collegeToReactSelectItem(college)))}
              onChange={(newColleges: ReactSelectOption[]) => setSelectedColleges(newColleges)}
              value={selectedColleges}
              placeholder='Enter any schools that you’ve helped clients get accepted to…'
            />
          : <div>
              {selectedColleges.map((college) => (
                <div key={college.label} className="badge bg-accent text-dark me-1 d-inline-block">{college.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Languages</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={Language.allAsSelectOptions()}
              onChange={(newLanguages: ReactSelectOption[]) => setSelectedLanguages(newLanguages)}
              value={selectedLanguages}
              placeholder="Select any languages you speak fluently…"
            />
          : <div>
              {selectedLanguages.map((languageItem) => (
                <div key={languageItem.value} className="badge bg-accent text-dark me-1 d-inline-block">{languageItem.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-6 d-flex align-items-center">
          <div className="mt-1 form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              disabled={!isEditing}
              checked={isOpenToWork}
              onChange={() => setIsOpenToWork(!isOpenToWork)}
            />
          </div>
          <div className="ms-2">
            <h5 className="mb-1">Profile Visibility</h5>
            <div className="fs-md">
              { isOpenToWork
              ? "Your profile is currently visible in the directory."
              : "Your profile is hidden from the directory, but can still be accessed by direct link."
              }
            </div>
          </div>
        </div>
        {htmlIf(isEditing,
          <div className="text-end">
            <button onClick={saveAboutMe} className="btn btn-primary mt-3 px-3">Save</button>
          </div>
        )}
      </div>
    </div>
  )
}

export default AboutMe;
