import { formatUSDWithCents, formatUSDWithoutCents } from "../Utils/Currency";
import { CustomerRating } from "./CustomerRating";

export type AdvisingPackage =
  { id: number
  , name: string
  , description?: string
  , numMinutes: number
  , totalPriceCents: number
  , refundPeriodLengthDays: number
  , activeForSale: boolean
  }

export function formatPackageHourlyRate(pkg: AdvisingPackage): string {
  const centsPerHour = pkg.totalPriceCents / (pkg.numMinutes / 60);
  return formatUSDWithoutCents(centsPerHour);
}

export type AdvisingSession =
  { id: number
  , advisorId: number
  , name: string
  , description?: string
  , numMinutes: number
  , amountCents: number
  , activeForSale: boolean
  }

export type AdvisingPurchase =
  { id: number
  , amountCents: number
  , status: PurchaseStatus
  , packageName?: string
  , sessionName?: string
  , isConsultation: boolean
  , advisorId: number
  , advisorName: string
  , advisorImageUrl: string
  , customerUserId: number
  , customerName: string
  , customerRating: CustomerRating
  , createdAt: string
  , paidAt: string
  , refundedAt: string
  }

export enum PurchaseStatus
  { AWAITING_PAYMENT = 'awaiting-payment'
  , ACTIVE = 'active'
  , COMPLETED = 'completed'
  , REFUNDED = 'refunded'
  }

export type AdvisingMeeting =
  { id: number
  , purchase: AdvisingPurchase
  , startAt: string
  , scheduledDurationMinutes: number
  , sessionGoals: string
  , sessionNotes: string
  , status: MeetingStatus
  , createdAt: string
  , completedAt: string
  , cancelledAt: string
  , rescheduledAt: string
  , zoomJoinUrl: string
  }

export enum MeetingStatus
  { ACTIVE = 'active'
  , COMPLETED = 'completed'
  , RESCHEDULED = 'rescheduled'
  , CANCELLED = 'cancelled'
  , CANCELLED_BY_ADVISOR = 'cancelled-by-advisor'
  }

export type AdvisingConsultInvitation =
  { id: number
  , customerEmail: string
  }
